<template>
	<b-modal size="xl" ref="modalHistory">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('monte.stock_semence.history') }}</h2>
		</template>
		<template slot="default">
			<CustomTable
				id_table="semence_tiers_history"
				ref="table"
				:items="stock"
				:busy.sync="table_busy"
				primaryKey="uid"
			/>
	    </template>
	</b-modal>
</template>

<script type="text/javascript">
    import StockSemence from "@/mixins/StockSemence"
	import Navigation from '@/mixins/Navigation'

	export default {
		name: 'ModalRetourStock',
		mixins: [StockSemence, Navigation],
		data () {
			return {
				stock: [],
				table_busy: false,
				tiers_id: null,
				horse_id: null,
				events_tab: {
					'TableAction::goToDeleteStockTiers': this.goToDeleteStock
				},
			}
		},

		methods: {
			async openModal(tiers_id, horse_id) {
				this.tiers_id = tiers_id
				this.horse_id = horse_id
				this.$refs.modalHistory.show()
				this.table_busy = true
				this.stock = await this.loadStockByTiersAndHorse(tiers_id, horse_id)
				this.table_busy = false
			},

			async goToDeleteStock(stock) {
				await this.deleteStock(stock.uid)
				this.$refs.table.refreshTable()
				this.openModal(this.tiers_id, this.horse_id)
				this.$emit("done", true)
			}
		},

		components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'), 
		}
	}

</script>